import { Component, Vue } from 'vue-property-decorator'

import { Variables } from '@/config/variables';

//esic components
import EContent from '@/components/esic/eContent/EContent.vue';

//wrappers
import {
	WColumn,
	WColumns,
	SizedBox,
	WLine,
	wIcon,
	SmoothDropdown,
	WButton,
	WImage,
} from '@/components/wrappers';

@Component({
	components:{
		WLine,
		wIcon,
		WImage,
		WColumn,
		WButton,
		WColumns,
		SizedBox,
		EContent,
		SmoothDropdown,
	}
})
export default class Question extends Vue { 
	variables = Variables;
}
